import React from "react"
import styled from "styled-components"

import { Container, Section } from "../global"
import phoneIcon from "../../images/phone.png"
import addressIcon from "../../images/marker.png"
import emailIcon from "../../images/email.png"

const Contact = () => {
  return (
    <StyledSection id="kontakt">
      <ContactContainer>
        <ContactTitle>Kontakt</ContactTitle>
      </ContactContainer>
      <ContactColumnContainer>
        <ContactColumn>
          <ImageWrapper>
            <img src={phoneIcon} alt="phone icon" />
          </ImageWrapper>
          <span>TELEFON</span>
          <ul>
            <li>
              <a href="tel:+48606834854">606 834 854</a>
            </li>
            <li>
              <a href="tel:+48505170057">505 170 057</a>
            </li>
          </ul>
        </ContactColumn>
        <ContactColumn>
          <ImageWrapper>
            <img src={addressIcon} alt="address icon" />
          </ImageWrapper>{" "}
          <span>ADRES</span>
          <ul>
            <li>
              <a
                href="https://goo.gl/maps/jTEyf9nE6ykmxacq7"
                target="_blank"
                rel="noopener noreferrer"
              >
                ul. Zwierzyńskiego 4, 95-080 Tuszyn
              </a>
            </li>
          </ul>
        </ContactColumn>
        <ContactColumn>
          <ImageWrapper>
            <img src={emailIcon} alt="email icon" />
          </ImageWrapper>{" "}
          <span>EMAIL</span>
          <ul>
            <li>
              <a href="mailto:tuszyn@onet.pl">tuszyn@onet.pl</a>
            </li>
          </ul>
        </ContactColumn>
      </ContactColumnContainer>
    </StyledSection>
  )
}

export default Contact

const StyledSection = styled(Section)`
  background-color: #2975dd;
  clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
`

const ContactContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0 40px;
`

const ContactTitle = styled.h3`
  margin: 0 auto 32px;
  text-align: center;
  color: ${props => props.theme.color.white.darker};
`

const ContactColumnContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 32px;
  justify-content: center;
  justify-items: center;
  text-align: center;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    grid-gap: 32px;
  }
`

const ImageWrapper = styled.div`
  margin: 10px auto;
  img {
    width: 50px;
  }
`

const ContactColumn = styled.div`
  span {
    font-size: 18px;
    ${props => props.theme.font.bold};
    color: ${props => props.theme.color.white.darker};
  }
  ul {
    list-style: none;
    margin: 16px 0;
    padding: 0;
    color: ${props => props.theme.color.white.darker};
    li {
      margin-bottom: 12px;
      ${props => props.theme.font.normal};
      font-size: 16px;
      a {
        color: ${props => props.theme.color.white.darker};
        text-decoration: none;
      }
    }
  }
`
