import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

const Features = () => (
  <Section id="usługi">
    <StyledContainer>
      <SectionTitle>Usługi</SectionTitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>Komputery</FeatureTitle>
          <FeatureText>
            Nowe, używane, serwis, naprawa, podzespoły, oprogramowanie,
            akcesoria
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Notebooki i tablety</FeatureTitle>
          <FeatureText>Nowe, używane, serwis, naprawa, akcesoria</FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Urządzenia peryferyjne</FeatureTitle>
          <FeatureText>
            Monitory, urządzenia wielofunkcyjne, drukarki, urządzenia sieciowe,
            myszki, klawiatury, słuchawki i mikrofony, głośniki, kamery,
            pendrive, karty pamięci
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Telefony</FeatureTitle>
          <FeatureText>
            Nowe, używane, serwis, naprawa, ładowarki, baterie, akcesoria,
            doładowania, karty sim
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Pięczątki</FeatureTitle>
          <FeatureText>
            Wykonujemy pieczątki różnych wielkości według ustalonego projektu
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
)

export default Features

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    text-align: center;
  }
`

const FeatureText = styled.p`
  text-align: center;
`
