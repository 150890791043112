import React from "react"
import styled from "styled-components"

import { Container } from "../global"

const Footer = () => (
  <FooterWrapper id="footer">
    <BrandContainer>
      <Logo>Komputery Tuszyn</Logo>
    </BrandContainer>
    <p>F.P.H Zbigniew i Dorota Koper</p>
  </FooterWrapper>
)

const FooterWrapper = styled.footer`
  background-color: white;
  margin: 0;
  padding: 0 0 40px;

  p {
    text-align: center;
    ${props => props.theme.font_size.xxsmall};
    color: ${props => props.theme.color.black.light};
  }
`

const Logo = styled.div`
  ${props => props.theme.font.bold};
  ${props => props.theme.font_size.regular};
  color: ${props => props.theme.color.black.regular};
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9;
  text-decoration: none;
  outline: 0px;
`

const BrandContainer = styled(Container)`
  position: relative;
  padding-top: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default Footer
